@import '../mixins';

.pageHeader {
  text-align: center;
  position: relative;
  @include tablet {
    border-bottom: 7px solid #8CC14A;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content:space-between;
    min-height: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    @include desktop {
      min-height: 116px;
    }
  }
  .pageHeader-logo {
    a, img {
      display: inline-block;
      vertical-align: top;
    }
    img {
      height: 70px;
      @include tablet {
        height: 100px;

      }
    }
  }
  .pageHeader-nav {
    color: #777777;
    font-size: 21px;
    line-height: 1.33;
    text-transform: uppercase;

    @media (max-width: $desktop-min-width - 1px) {
      background: #fff;
      position: fixed;
      top: 0px;
      left: 0;
      bottom: 0;
      width: 60vw;
      max-width: 300px;
      padding-top: 50px;
      z-index: 10;
      transform: translateX(-100%);
      transition: 0.3s ease-in-out;
      &.open {
        transform: translateX(0%);
      }
      #closeMenuButton {
        background: url(../images/icon-close.svg) center no-repeat;
        background-size: 20px 20px;
        display: inline-block;
        height: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
      }
      ul {
        border-top: 1px solid #F3E8E4;
        display: block;
        margin: 0;
        li {
          border-bottom: 1px solid #F3E8E4;
          margin: 0;
          padding: 10px 20px;
          a {
            display: block;
            margin: -10px -20px;
            padding: 10px 20px;
          }
        }
      }
      .pageHeader-buttons {
        margin-top: 20px;
      }
    }
    @include desktop {
      display: flex;
      align-items: center; 
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      @include desktop {
        display: flex;
      }
      li {
        margin: 0 20px;
      }
    }
  }
  .pageHeader-navOverlay {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
  }
  .pageHeader-nav.open ~ .pageHeader-navOverlay {
    opacity: 1;
    pointer-events: all;
  }
  .pageHeader-mobileBars {
    background: url(../images/icon-menu.svg) center no-repeat;
    display: block;
    height: 48px;
    width: 48px;
    @include desktop {
      display: none;
    }
  }
}
