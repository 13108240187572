@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import 'mixins';

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}
html {
  color: #000;
  font-size: 10px;
  @include desktop {
    font-size: 12px;
  }
}
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
}
input, textarea, select {
  font-family: 'Montserrat', sans-serif;
}
a {
  cursor: pointer;
  color: inherit;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    color: inherit;
  }
}
img {
  max-width: 100%;
}
ul {
  margin: 0;
  padding: 0;
}
a, button {
  cursor: pointer;
}
