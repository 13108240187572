@import '../mixins';

.packagesPage {
  .packagesList {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    align-items: flex-end;
    margin-bottom: 30px;
  } 
  .packageRow {
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    color: #364135;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    &:hover {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    @include tablet {
      &:nth-child(1), &:nth-child(5) {
        min-height: 150px;
      }
      &:nth-child(2), &:nth-child(4) {
        min-height: 175px;
      }
      &:nth-child(3) {
        min-height: 200px;
      }
    }
    .packageRow-size {
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 5px;
    }
    .packageRow-text {
      font-size: 14px
    }
    .packageRow-price {
      color: $green;
      font-weight: bold;
      font-size: 20px;
      margin: 10px 0;
    }
    
  }
  .packageActions {
    margin-top: 100px;
  }
}