.alert {
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  margin: 0 0 20px 0;
  padding: 1rem 2rem;
}
.alert-success, .alert-notice {
  background: #0abb87;
  border: 1px solid #0abb87;
  color: #fff;
}
.alert-error, .alert-alert {
  background: #fd397a;
  border: 1px solid #fd397a;
  color: #fff;
}