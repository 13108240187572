@import '../mixins';

.homePage {
  .homePage-banner {
    background: url(../images/homepage-bg.jpg);
    background-size: cover;
    background-position: right center;
    padding: 50px 0;
    min-height: calc(100vh - 250px - 130px - 7px);
    .homePage-button {
      text-align: center;
      a {
        background: #8CC14A;
        border-radius: 20px;
        color: #fff;
        display: inline-block;
        padding: 10px 50px;
        @include tablet {
          padding: 10px 100px;

        }
        &:hover {
          background: darken(#8CC14A, 10%);
        }
        strong {
          display: block;
          font-size: 26px;
          margin: 0;
          @include tablet {
            font-size: 34px;
          }
        }
        span {
          font-size: 16px;
          margin: 0;

        }
      }
    }

    .homeCategories {
      display: block;
      margin: 30px -10px;
      text-align: center;
      .homeCategory {
        background: rgb(196,255,130);
        background: linear-gradient(135deg, rgba(196,255,130,1) 0%, rgba(144,207,76,1) 30%, rgba(133,196,64,1) 100%);
        border-bottom: 3px solid #007446;
        border-radius: 15px;
        display: inline-block;
        height: 124px;
        margin: 5px 10px;
        position: relative;
        width: 124px;

        .homeCategory-image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 20px;
          img {
            max-height: 90px;
          }
        }
        .homeCategory-name {
          background: rgba(#BFDBA0, 0.9);
          border-radius: 0 0 15px 15px;
          color: #007446;
          font-size: 13px;
          font-weight: 600;
          height: 60px;
          padding: 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
    .homeStores {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      margin-top: 50px;
      @include desktop {
        margin-top: 100px;
      }
      img {
        display: block;
        margin: 5px;
      }
    }
  }

  .homeReviews {
    margin: 30px auto;
    max-width: 800px;
    .homeReview {
      padding: 0 40px;
      @include tablet {
        display: flex;
        align-items: center;
      }
      @include desktop {
        padding: 0 50px;
      }
      .homeReview-logo {
        flex-shrink: 0;
        text-align: center;
        margin: 0 auto 20px;
        width: 150px;
        img {
          border: 1px solid #707070;
          border-radius: 50%;
          width: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
        }
        @include tablet {
          width: 120px;
          margin-bottom: 0;
        }
        @include desktop {
          width: 150px;
        }
      }
      .homeReview-texts {
        flex-grow: 1;
        @include tablet {
          padding-left: 20px;
        }
        .homeReview-title {
          color: #8CC14A;
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 5px;
          @include desktop {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        .homeReview-content {
          color: #707070;
          font-style: italic;
          font-size: 13px;
          line-height: 1.5;
          margin-bottom: 5px;
          @include desktop {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
        .homeReview-author {
          color: #8CC14A;
          font-style: italic;
          font-size: 14px;
          text-align: right;
          @include desktop {
            font-size: 16px;
          }
        }
      }
    }
    .swiper-button-prev, .swiper-button-next {
      height: 33px;
      width: 20px;
      &::after {
        font-size: 33px;
      }
      @include desktop {
        height: 44px;
        width: 27px;
        &::after {
          font-size: 44px;
        } 
      }
    }
  }
}