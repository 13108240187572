$dark: #333;
$dark-active: #222;
$sidebar-size: 250px;
$primary: #6981EC;
$green: #85C440;

$tablet-min-width: 768px;
$desktop-min-width: 992px;
$desktop-lg-min-width: 1200px;
$desktop-hd-min-width: 1800px;


@mixin mobile {
  @media (min-width: 400px) {
    @content
  }
}

@mixin tablet {
  @media (min-width: $tablet-min-width) {
    @content;
  }
}

@mixin below-desktop {
  @media (max-width: $desktop-min-width - 1px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-min-width) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: $desktop-lg-min-width) {
    @content;
  }
}

@mixin desktop-hd {
  @media (min-width: $desktop-hd-min-width) {
    @content;
  }
}
