@import '../mixins';

.ratesPage {

  .headingText {
    text-align: center;
  }

  .rateStar {
    background: url(../images/ratae-star.svg) center no-repeat;
    background-size: cover;
    height: 120px;
    width: 120px;
    display: flex;
    font-size: 24px;
    font-weight: bold;;
    align-items: center;
    justify-content:center;
    margin: 20px auto;
  }

  .rateRow {
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    color: #364135;
    margin: 0 0 20px;
    padding: 10px 20px;
    .rateRow-author {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      span.rateRow-star {
        background: url(../images/ratae-star.svg) right center no-repeat;
        display: inline-block;
        line-height: 20px;
        padding-right: 20px;
        vertical-align: middle;
      }
    }
    .rateRow-text {
      max-width: 60%;
      .rateRow-date {
        font-size: 12px;
        display: inline-block;
        margin-right: 20px;
      }
      .rateRow-content {
        display: inline-block;
      }
    }
    .rateAnswer {
      margin-left: auto;
      @include tablet {
        max-width: 60%;
      }
      .rateAnswer-label {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .rateAnswer-text {
        font-style: italic;
      }
    }
  }
  .rateForm {
    .rateForm-layout {
      @include tablet {
        display: grid;
        grid-template-columns: auto 150px;
        grid-gap: 20px;
      }
    }
  }
}