@import '../mixins';

.subscriptionsPage {
  .subscriptionCategory {
    display: grid;
    grid-template-columns: auto 200px 200px;
    align-items: center;
    margin: 0 0 20px;
    & > * {
      padding: 5px;
    }
    .subscriptionCategory-expired_date {
      text-align: center;
    }
    .subscriptionCategory-action {
      text-align: center;
    }
    &.subscriptionCategory--heading {
      border-bottom: 1px solid #000;
      font-weight: bold;
    }
  }
}