@import './resets';
@import './mixins';

@import 'flatpickr/dist/flatpickr.min';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@import './page/header';
@import './page/footer';
@import './page/alerts';
@import './page/home';
@import './page/dashboard';
@import './page/static-page';
@import './page/chats';
@import './page/offers';
@import './page/packages';
@import './page/notifications';
@import './page/rates';
@import './page/subscriptions';
@import './page/form';

:root {
  --swiper-theme-color: #BFDBA0;
}

body {
  color: #2F2F2F;
  font-family: 'Open Sans', sans-serif;
}

.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}

.confirmMessage {
  background: #fafafa;
  border: 1px solid #ccc;
  color: #666;
  font-size: 16px;
  padding: 15px;
  text-align: center;
}

.btn.btn-primary, .btn.btn-secondary {
  border-radius: 26px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  display: inline-block;
  line-height: 23px;
  padding: 7px 30px;
  text-transform: uppercase;
  text-align: center;
  &.btn-large {
    line-height: 22px;
    padding: 15px;
  }
}
.btn.btn-primary {
  background: transparent linear-gradient(120deg, #85C440 0%, #6BAB26 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}
.btn.btn-secondary {
  background: #C5C5C5;
  color: #707070;
}

.bgContent {
  @include desktop {
    margin: 0 -15px;
    min-height: 600px;
    padding: 0 30px 0 350px;
    position: relative;
    &::before {
      background: url(../images/content-bg.jpg) center no-repeat;
      background-size: auto 100%;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 312px;
    }
  }
  @include desktop-lg {
    padding-right: 100px;
    padding-left: 380px;
    &::before {
      width: 350px;
    }
  }
}

.headingText {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px;
}
.actionsList {
  text-align: right;
  margin: 20px 0;
}

.userLayout {
  padding: 20px 0;
  @include tablet {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 20px;
    min-height: calc(100vh - 337px);
  }

  .userLayout-sidebarLabel {
    background-color: #85C440;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    color: #fff;
    display: block;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    @include tablet {
      display: none;
    }
  }
  .userLayout-sidebar .userMenu {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
.userMenu {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0 0 15px;
    a {
      background-color: #f5f5f5;
      border-radius: 20px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
      color: #364135;
      display: block;
      font-weight: bold;
      padding: 20px;
      text-align: center;
      text-transform: uppercase;
      &.active {
        background-color: #85C440;
        color: #fff;
      }
      &:hover {
        background-color: #85C440;
        color: #fff;
      }
      & > span {
        display: inline-block;
        position: relative;
        & > span {
          background-color: #fff;
          border-radius: 50%;
          color: #85C440;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          height: 20px;
          width: 20px;
          position: absolute;
          top: -8px;
          right: -20px;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
  & > em, & > span, & > a {
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    line-height: 20px;
    margin: 0 2px;
    padding: 5px;
  }
  & > span {
    color: #999;
  }
  & > em {
    font-weight: bold;
    font-style: normal;
  }
  & > a:hover {
    background-color: #8CC14A;
    color: #fff;
  }
}