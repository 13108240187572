@import '../mixins';

.staticPage {
  padding: 50px 0;
  .staticPage-title {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 20px;
    text-align: center;
  }
  .staticPage-content {
    p {
      margin-bottom: 15px;
    }
  }
}