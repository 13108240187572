@import '../mixins';

.notificationsPage {
  .notificationsCalculator {
    max-width: 600px;
    margin: 50px auto;
    .notificationsCalculator-row {
      display: grid;
      font-size: 14px;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      margin: 10px 0;
      .notificationsCalculator-label {
        text-align: right;
      }
      .notificationsCalculator-value {
        font-size: 16px;
      }
      #total_price {
        color: #00AD53;
        font-weight: bold;
      }
    }
  }
}