@import '../mixins';

.chatsHeader {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headingText {
    margin-bottom: 0;
  }
  .chatsNotifications {
    text-align: right;
  }
}

.chatsPage {
  .chatsList {
    max-width: 600px;
  } 
  .chatRow {
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    color: #364135;
    display: grid;
    grid-template-columns: 60px 1fr 100px;
    grid-gap: 15px;
    margin: 0 0 20px;
    padding: 20px;
    &:hover {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    &.chatRow--unread {
      -webkit-box-shadow: 0px 0px 6px 0px rgba(133, 196, 64, 1);
      -moz-box-shadow: 0px 0px 6px 0px rgba(133, 196, 64, 1);
      box-shadow: 0px 0px 6px 0px rgba(133, 196, 64, 1);
      font-weight: bold;
    }
    .chatRow-avatar {
      img {
        border-radius: 50%;
        display: block;
        height: 60px;
        width: 60px;
      }
    }
    .chatRow-text {
      h3 {
        font-weight: 600;
        margin: 0 0 5px;
      }
      p {
        font-size: 11px;
        margin: 0;
      }
    }
    .chatRow-date {
      color: #364135;
      font-size: 11px;
      text-align: right;
    }
  }
}
.chatPage {
  .chatPage-user {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .chatPage-userAvatar {
      margin-right: 20px;
      width: 60px;
      img {
        border-radius: 50%;
        display: block;
        height: 60px;
        width: 60px;;
      }
    }
    .chatPage-userName {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .chatPage-messages {
    display: flex;
    flex-direction: column;
    .chatPage-message {
      background: #fff;
      border: 2px solid $green;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 10px;
      width: 300px;
      .chatPage-messageText {
        margin-bottom: 5px;
        p {
          margin: 0;
        }
      }
      .chatPage-messageMeta {
        color: #999;
        font-size: 12px;
        text-align: right;
      }
      &:not(.chatPage-messageMine) {
        background: $green;
        align-self: flex-end;
        color: #fff;
        .chatPage-messageText {
          text-align: right;
        }
        .chatPage-messageMeta {
          color: #ddd;
        }
      }
      &.chatPage-messageMine {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      }
    }
  }
  .chatForm {
    margin: 20px 0;
    .chatForm-layout {
      display: grid;
      grid-template-columns: auto 120px;
      grid-gap: 20px;
      align-items: center;
      .form-group {
        margin: 0;
      }
    }
  }
}

.notificationSwitch {
  display: inline-block;
  line-height: 20px;
  margin: 10px 0;
  padding-left: 45px;
  position: relative;
  &::before {
    border-radius: 10px;
    border: 2px solid #ddd;
    content: "";
    height: 20px;
    width: 35px;
    position: absolute;
    top: 0;
    left: 0;
    transition: .4s;
  }
  &::after {
    content: '';
    background: #85C440;
    border-radius: 8px;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 16px;
    width: 16px;
  }
  &.notificationSwitch-disabled {
    &::after {
      background: #aaa;
      left: 17px;
    }
  }
}