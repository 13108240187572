@import '../mixins';

.pageFooter {
  background: #8CC14A;
  color: #fff;
  font-size: 16px;
  min-height: 250px;
  padding: 40px 0;
  .pageFooter-layout {
    display: grid;
    grid-gap: 30px;
    @include tablet {
      grid-gap: 0;
      grid-template-columns: 1fr 200px 200px;
    }
    @include desktop {
      grid-template-columns: 1fr 250px 250px;
    }
    h4 {
      margin: 0 0 15px;
    }
    ul.pageFooter-informations {
      list-style-type: circle;
      list-style-position: outside;
      line-height: 1.5;
      margin: 0 0 0 20px;
      padding: 0;
      li {
        margin: 4px 0;
      } 
    }
    ul.pageFooter-menu {
      line-height: 1.5;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 8px 0;
      }
    }
  }
}