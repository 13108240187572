@import '../mixins';

.offersPage {
  .offersHeading {
    color: $green;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 10px;
  }
  .offersList {
    margin-bottom: 50px;
    max-width: 600px;
  } 
  .offerRow {
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    color: #364135;
    display: grid;
    grid-template-columns: 60px 1fr 100px;
    grid-gap: 15px;
    align-items: center;
    margin: 0 0 20px;
    padding: 10px 20px;
    &:hover {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .offerRow-image {
      img {
        border-radius: 50%;
        display: block;
        height: 60px;
        width: 60px;
      }
    }
    .offerRow-text {
      h3 {
        font-weight: 600;
        margin: 0 0 5px;
      }
      p {
        font-size: 11px;
        margin: 5px 0 0;
      }
    }
  }
}

.offerForm {
  max-width: 600px;

  .form-group.offer_category {
    display: block;
    margin: 30px 0;
    .form-control-label {
      font-weight: bold;
      margin: 0 0 10px;
    }
    .form-control-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
      max-width: 600px;
      span.radio ~ span.radio {
        margin-top: 0;
      }
    }
  }
}

.offerAskForm {
  text-align: center;
  p {
    margin: 0 0 20px;
  }
}