@import '../mixins';

.dashboardPage {
  .dashboardBoxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    max-width: 700px;
    .dashboardBox {
      background: #f5f5f5 left 10px center no-repeat;;
      background-size: auto 100px;
      border-radius: 20px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06); 
      padding: 20px 20px 20px 130px;
      min-height: 140px;
      h3 {
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 10px;
      }
      h4 {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        strong {
          color: $green;
          display: block;
          font-size: 36px;
          font-weight: bold;
        }
      }
    }
    .dashboardBox-company {
      background-image: url(../images/icon-stats-company.svg);
    }
    .dashboardBox-offers {
      background-image: url(../images/icon-stats-offers.svg);
    }
    .dashboardBox-credits {
      background-image: url(../images/icon-stats-credits.svg);
    }
  }
}