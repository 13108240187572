@import '../mixins';

.loginPage .bgContent {
  padding-top: 50px;
  padding-bottom: 50px;
}

form.pageForm {
  .headingText {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 20px;
    text-align: center;
  }
  .form-group {
    display: flex;
    align-items: flex-start;
    margin: 0 0 10px;
  }
  .form-group.hidden {
    display: none;
  }
  .form-control-label {
    padding-top: 8px;
    padding-right: 10px;
    text-align: right;
    width: 150px;
  }
  .form-control-wrapper, .form-wrapper {
    flex-grow: 1;
  }
  abbr {
    color: #fd397a;
    font-weight: bold;
    text-decoration: none;
  }
  .form-control {
    background: #fff;
    border: 1px solid #C5C5C5;
    border-radius: 17.5px;
    display: block;
    line-height: 25px;
    padding: 5px 15px;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    &:focus {
      border-color: #8CC14A;
    }
  }
  .invalid-feedback {
    color: red;
    display: block;
    font-size: 80%;
    margin: 0 0 5px;
  }
  .form-actions {
    padding-left: 150px;
    input {
      max-width: 100%;
    }
  }

  .form-group.file {
    .fileInput {
      display: inline-flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      &::after {
        border: 2px solid #fff;
        border-radius: 10px;
        content: '+';
        display: inline-block;
        font-weight: bold;
        height: 20px;
        line-height: 16px;
        margin-left: 5px;
        width: 20px;
      }
      & ~ input[type=file] {
        display: none;
      }
    }
  }

  .form-group.check_boxes {
    position: relative;
    span.checkbox {
      display: block;
      & ~ span.checkbox {
        margin-top: 10px;
      }
    }
    .form-control-label {
      display: block;
      text-align: left;
      width: 100%;
    }
    .collection_check_boxes {
      cursor: pointer;
      display: inline-block;
      font-weight: normal;
      line-height: 20px;
      text-align: left;
      padding: 2px 0 2px 30px;
      position: relative;
      width: unset;
      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 24px;
        width: 24px;
        left: 0;
      }
      &::before {
        border: 2px solid #C5C5C5;
        border-radius: 5px;
      }
      &::after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjOENDMTRBIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlsaW5lIHBvaW50cz0iMjAgNiA5IDE3IDQgMTIiLz48L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        opacity: 0;
      }
    }
    input[type=checkbox] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }
    input[type=checkbox]:checked ~ .collection_check_boxes {
      &::before {
        border-color: #8CC14A;
      }
      &::after {
        opacity: 1;
      }
    }
  }
  .form-group.radio_buttons {
    position: relative;
    span.radio {
      display: block;
      & ~ span.radio {
        margin-top: 10px;
      }
    }
    .form-control-label {
      display: block;
      text-align: left;
      width: 100%;
    }
    .collection_radio_buttons {
      cursor: pointer;
      display: inline-block;
      font-weight: normal;
      line-height: 20px;
      text-align: left;
      padding: 2px 0 2px 30px;
      position: relative;
      width: unset;
      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 24px;
        width: 24px;
        left: 0;
      }
      &::before {
        border: 2px solid #C5C5C5;
        border-radius: 5px;
      }
      &::after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjOENDMTRBIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlsaW5lIHBvaW50cz0iMjAgNiA5IDE3IDQgMTIiLz48L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        opacity: 0;
      }
    }
    input[type=radio] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }
    input[type=radio]:checked ~ .collection_radio_buttons {
      &::before {
        border-color: #8CC14A;
      }
      &::after {
        opacity: 1;
      }
    }
  }
  .form-group.boolean {
    position: relative;
    .form-check-label {
      display: inline-block;
      font-weight: normal;
      line-height: 20px;
      text-align: left;
      padding: 2px 0 2px 30px;
      width: unset;
      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 24px;
        width: 24px;
        left: 0;
      }
      &::before {
        border: 2px solid #C5C5C5;
        border-radius: 5px;
      }
      &::after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjOENDMTRBIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlsaW5lIHBvaW50cz0iMjAgNiA5IDE3IDQgMTIiLz48L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        opacity: 0;
      }
    }
    input[type=checkbox] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }
    input[type=checkbox]:checked ~ .form-check-label {
      &::before {
        border-color: #8CC14A;
      }
      &::after {
        opacity: 1;
      }
    }
  }
}
form.loginForm {
  margin: 0 auto 50px;
  max-width: 360px;
  .form-control-label {
    width: 100px;
  }
  .form-actions {
    padding-left: 100px;
  }

}

.registerInfo {
  border-top: 1px dashed #8CC14A;
  margin: 30px auto;
  max-width: 500px;
  padding-top: 30px;
  text-align: center;
  .headingText {
    margin-bottom: 10px;
  }
  .registerInfo-subheading {
    color: #2F2F2F;
    font-size: 15px;
    margin: 10px 0;
  }
  .registerInfo-text {
    color: #2F2F2F;
    font-size: 13px;
    margin: 10px auto;
  }
  .registerInfo-buttons {
    margin-top: 30px;
  }
}

.registerPage {
  .bgContent {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pageForm .headingText {
    margin: 0;
  }
  .registerPage-step {
    color: #8CC14A;
    margin: 0 0 30px;
    text-align: center;
  }
}

form.accountForm {
  .form-control-label {
    font-weight: bold;
  }
  .registerStep1 {
    margin: 0 auto 50px;
    max-width: 600px;
    .form-group {
      max-width: 480px
    }
    .form-control-label {
      width: 230px;
    }
    .form-wrapper {
      .form-control {
        width: 100%;
      }
    }
    .form-actions {
      margin-top: 30px;
      text-align: right;
    }
  }
  .registerStep2 {
    margin: 0 auto 50px;
    max-width: 600px;

    .form-group {
      .form-control-label {
        width: 230px;
      }
    }
    .form-group.company_images {
      align-items: flex-start;
      
    }
    .form-group.file {
      .fileInput {
        display: inline-flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        &::after {
          border: 2px solid #fff;
          border-radius: 10px;
          content: '+';
          display: inline-block;
          font-weight: bold;
          height: 20px;
          line-height: 16px;
          margin-left: 5px;
          width: 20px;
        }
        & ~ input[type=file] {
          display: none;
        }
      }
    }
    .form-group.string, .form-group.tel, .form-group.text, .form-group.file {

    }
    .form-group {
      max-width: 480px
    }
    .hoursInputs-label {
      font-weight: bold;
      text-align: right;
      width: 200px;
    }
    .hoursInputs {
      display: grid;
      .hoursInputs-layout {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 100px 1fr 1fr;
        align-items: flex-start;
        margin: 0 0 10px;
        @include tablet {
          grid-template-columns: 200px 1fr 1fr;
        }
        .hoursInputs-rowLabel {
          display: inline-block;
          margin: 5px 0;
          text-align: right;
        }
        .form-group {
          margin: 0;
        }

      }
    }
    .form-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      .btn {
        margin: 5px;
      }
    }
  }
  .form-group.category_list_wrapper {
    .form-control-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
      max-width: 600px;
      margin: 0 auto;
      span.radio {
        padding-bottom: 30px;
        position: relative;
        text-align: center;
        & ~ span.radio {
          margin: 0;
        }
        .collection_radio_buttons {
          font-weight: bold;
          text-align: center;
          padding: 0;
          position: unset;
          width: 100%;
          &::before, &::after {
            top: unset;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      span.checkbox {
        padding-bottom: 30px;
        position: relative;
        text-align: center;
        & ~ span.checkbox {
          margin: 0;
        }
        .collection_check_boxes {
          font-weight: bold;
          text-align: center;
          padding: 0;
          position: unset;
          width: 100%;
          &::before, &::after {
            top: unset;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  .registerPage-category {
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .registerPage-categoryName {
      color: #8CC14A;
      font-weight: bold;
      margin: 0 0 10px;
      text-align: center;
      grid-column: 1 / 3;
    }
    .registerPage-filters {
      display: grid;
      grid-column-gap: 30px;
      grid-template-columns: 1fr 1fr;
      .registerPage-filtersLabel {
        font-weight: bold;
        line-height: 20px;
        text-align: right;
      }
    }
  } 
}

form.registerStep2Form {
  
}
form.registerStep3Form {
  
  .form-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    .btn {
      margin: 5px;
    }
  }
}

#images_preview  {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px;
  .image_box {
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin: 5px;
    position: relative;
    width: 80px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
    .image_remove_btn {
      background: url(../images/icon-close.svg);
      background-size: contain;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }  
}

#logo_preview  {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px;
  .logo_box {
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin: 5px;
    position: relative;
    width: 80px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
    .logo_remove_btn {
      background: url(../images/icon-close.svg);
      background-size: contain;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }  
}

#documents_preview  {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px;
  .document_box {
    background: url(../images/icon-document.svg) center no-repeat;
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin: 5px;
    position: relative;
    width: 80px;
    .document_remove_btn {
      background: url(../images/icon-close.svg);
      background-size: contain;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
    }
    .document_title {
      line-break: anywhere;
      position: absolute;
      top: 80px;
    }
  }  
}
