@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

*::before, *::after {
  box-sizing: border-box;
}

html {
  color: #000;
  font-size: 10px;
}
@media (min-width: 992px) {
  html {
    font-size: 12px;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
}

input, textarea, select {
  font-family: "Montserrat", sans-serif;
}

a {
  cursor: pointer;
  color: inherit;
  transition: 0.3s ease-in-out;
  text-decoration: none;
}
a:hover {
  color: inherit;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

a, button {
  cursor: pointer;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month { /*
/*rtl:begin:ignore*/
  left: 0;
} /*
/*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month { /*
/*rtl:begin:ignore*/
  right: 0;
} /*
/*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.pageHeader {
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  .pageHeader {
    border-bottom: 7px solid #8CC14A;
  }
}
.pageHeader .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 992px) {
  .pageHeader .container {
    min-height: 116px;
  }
}
.pageHeader .pageHeader-logo a, .pageHeader .pageHeader-logo img {
  display: inline-block;
  vertical-align: top;
}
.pageHeader .pageHeader-logo img {
  height: 70px;
}
@media (min-width: 768px) {
  .pageHeader .pageHeader-logo img {
    height: 100px;
  }
}
.pageHeader .pageHeader-nav {
  color: #777777;
  font-size: 21px;
  line-height: 1.33;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .pageHeader .pageHeader-nav {
    background: #fff;
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    width: 60vw;
    max-width: 300px;
    padding-top: 50px;
    z-index: 10;
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
  }
  .pageHeader .pageHeader-nav.open {
    transform: translateX(0%);
  }
  .pageHeader .pageHeader-nav #closeMenuButton {
    background: url(../images/icon-close.svg) center no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
  }
  .pageHeader .pageHeader-nav ul {
    border-top: 1px solid #F3E8E4;
    display: block;
    margin: 0;
  }
  .pageHeader .pageHeader-nav ul li {
    border-bottom: 1px solid #F3E8E4;
    margin: 0;
    padding: 10px 20px;
  }
  .pageHeader .pageHeader-nav ul li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  .pageHeader .pageHeader-nav .pageHeader-buttons {
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .pageHeader .pageHeader-nav {
    display: flex;
    align-items: center;
  }
}
.pageHeader .pageHeader-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 992px) {
  .pageHeader .pageHeader-nav ul {
    display: flex;
  }
}
.pageHeader .pageHeader-nav ul li {
  margin: 0 20px;
}
.pageHeader .pageHeader-navOverlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
}
.pageHeader .pageHeader-nav.open ~ .pageHeader-navOverlay {
  opacity: 1;
  pointer-events: all;
}
.pageHeader .pageHeader-mobileBars {
  background: url(../images/icon-menu.svg) center no-repeat;
  display: block;
  height: 48px;
  width: 48px;
}
@media (min-width: 992px) {
  .pageHeader .pageHeader-mobileBars {
    display: none;
  }
}

.pageFooter {
  background: #8CC14A;
  color: #fff;
  font-size: 16px;
  min-height: 250px;
  padding: 40px 0;
}
.pageFooter .pageFooter-layout {
  display: grid;
  grid-gap: 30px;
}
@media (min-width: 768px) {
  .pageFooter .pageFooter-layout {
    grid-gap: 0;
    grid-template-columns: 1fr 200px 200px;
  }
}
@media (min-width: 992px) {
  .pageFooter .pageFooter-layout {
    grid-template-columns: 1fr 250px 250px;
  }
}
.pageFooter .pageFooter-layout h4 {
  margin: 0 0 15px;
}
.pageFooter .pageFooter-layout ul.pageFooter-informations {
  list-style-type: circle;
  list-style-position: outside;
  line-height: 1.5;
  margin: 0 0 0 20px;
  padding: 0;
}
.pageFooter .pageFooter-layout ul.pageFooter-informations li {
  margin: 4px 0;
}
.pageFooter .pageFooter-layout ul.pageFooter-menu {
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0;
}
.pageFooter .pageFooter-layout ul.pageFooter-menu li {
  margin: 8px 0;
}

.alert {
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  margin: 0 0 20px 0;
  padding: 1rem 2rem;
}

.alert-success, .alert-notice {
  background: #0abb87;
  border: 1px solid #0abb87;
  color: #fff;
}

.alert-error, .alert-alert {
  background: #fd397a;
  border: 1px solid #fd397a;
  color: #fff;
}

.homePage .homePage-banner {
  background: url(../images/homepage-bg.jpg);
  background-size: cover;
  background-position: right center;
  padding: 50px 0;
  min-height: calc(100vh - 250px - 130px - 7px);
}
.homePage .homePage-banner .homePage-button {
  text-align: center;
}
.homePage .homePage-banner .homePage-button a {
  background: #8CC14A;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  padding: 10px 50px;
}
@media (min-width: 768px) {
  .homePage .homePage-banner .homePage-button a {
    padding: 10px 100px;
  }
}
.homePage .homePage-banner .homePage-button a:hover {
  background: #72a137;
}
.homePage .homePage-banner .homePage-button a strong {
  display: block;
  font-size: 26px;
  margin: 0;
}
@media (min-width: 768px) {
  .homePage .homePage-banner .homePage-button a strong {
    font-size: 34px;
  }
}
.homePage .homePage-banner .homePage-button a span {
  font-size: 16px;
  margin: 0;
}
.homePage .homePage-banner .homeCategories {
  display: block;
  margin: 30px -10px;
  text-align: center;
}
.homePage .homePage-banner .homeCategories .homeCategory {
  background: rgb(196, 255, 130);
  background: linear-gradient(135deg, rgb(196, 255, 130) 0%, rgb(144, 207, 76) 30%, rgb(133, 196, 64) 100%);
  border-bottom: 3px solid #007446;
  border-radius: 15px;
  display: inline-block;
  height: 124px;
  margin: 5px 10px;
  position: relative;
  width: 124px;
}
.homePage .homePage-banner .homeCategories .homeCategory .homeCategory-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.homePage .homePage-banner .homeCategories .homeCategory .homeCategory-image img {
  max-height: 90px;
}
.homePage .homePage-banner .homeCategories .homeCategory .homeCategory-name {
  background: rgba(191, 219, 160, 0.9);
  border-radius: 0 0 15px 15px;
  color: #007446;
  font-size: 13px;
  font-weight: 600;
  height: 60px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
}
.homePage .homePage-banner .homeStores {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 50px;
}
@media (min-width: 992px) {
  .homePage .homePage-banner .homeStores {
    margin-top: 100px;
  }
}
.homePage .homePage-banner .homeStores img {
  display: block;
  margin: 5px;
}
.homePage .homeReviews {
  margin: 30px auto;
  max-width: 800px;
}
.homePage .homeReviews .homeReview {
  padding: 0 40px;
}
@media (min-width: 768px) {
  .homePage .homeReviews .homeReview {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .homePage .homeReviews .homeReview {
    padding: 0 50px;
  }
}
.homePage .homeReviews .homeReview .homeReview-logo {
  flex-shrink: 0;
  text-align: center;
  margin: 0 auto 20px;
  width: 150px;
}
.homePage .homeReviews .homeReview .homeReview-logo img {
  border: 1px solid #707070;
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 768px) {
  .homePage .homeReviews .homeReview .homeReview-logo {
    width: 120px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .homePage .homeReviews .homeReview .homeReview-logo {
    width: 150px;
  }
}
.homePage .homeReviews .homeReview .homeReview-texts {
  flex-grow: 1;
}
@media (min-width: 768px) {
  .homePage .homeReviews .homeReview .homeReview-texts {
    padding-left: 20px;
  }
}
.homePage .homeReviews .homeReview .homeReview-texts .homeReview-title {
  color: #8CC14A;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}
@media (min-width: 992px) {
  .homePage .homeReviews .homeReview .homeReview-texts .homeReview-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.homePage .homeReviews .homeReview .homeReview-texts .homeReview-content {
  color: #707070;
  font-style: italic;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 5px;
}
@media (min-width: 992px) {
  .homePage .homeReviews .homeReview .homeReview-texts .homeReview-content {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.homePage .homeReviews .homeReview .homeReview-texts .homeReview-author {
  color: #8CC14A;
  font-style: italic;
  font-size: 14px;
  text-align: right;
}
@media (min-width: 992px) {
  .homePage .homeReviews .homeReview .homeReview-texts .homeReview-author {
    font-size: 16px;
  }
}
.homePage .homeReviews .swiper-button-prev, .homePage .homeReviews .swiper-button-next {
  height: 33px;
  width: 20px;
}
.homePage .homeReviews .swiper-button-prev::after, .homePage .homeReviews .swiper-button-next::after {
  font-size: 33px;
}
@media (min-width: 992px) {
  .homePage .homeReviews .swiper-button-prev, .homePage .homeReviews .swiper-button-next {
    height: 44px;
    width: 27px;
  }
  .homePage .homeReviews .swiper-button-prev::after, .homePage .homeReviews .swiper-button-next::after {
    font-size: 44px;
  }
}

.dashboardPage .dashboardBoxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  max-width: 700px;
}
.dashboardPage .dashboardBoxes .dashboardBox {
  background: #f5f5f5 left 10px center no-repeat;
  background-size: auto 100px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  padding: 20px 20px 20px 130px;
  min-height: 140px;
}
.dashboardPage .dashboardBoxes .dashboardBox h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px;
}
.dashboardPage .dashboardBoxes .dashboardBox h4 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.dashboardPage .dashboardBoxes .dashboardBox h4 strong {
  color: #85C440;
  display: block;
  font-size: 36px;
  font-weight: bold;
}
.dashboardPage .dashboardBoxes .dashboardBox-company {
  background-image: url(../images/icon-stats-company.svg);
}
.dashboardPage .dashboardBoxes .dashboardBox-offers {
  background-image: url(../images/icon-stats-offers.svg);
}
.dashboardPage .dashboardBoxes .dashboardBox-credits {
  background-image: url(../images/icon-stats-credits.svg);
}

.staticPage {
  padding: 50px 0;
}
.staticPage .staticPage-title {
  font-size: 36px;
  font-weight: bold;
  margin: 0 0 20px;
  text-align: center;
}
.staticPage .staticPage-content p {
  margin-bottom: 15px;
}

.chatsHeader {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chatsHeader .headingText {
  margin-bottom: 0;
}
.chatsHeader .chatsNotifications {
  text-align: right;
}

.chatsPage .chatsList {
  max-width: 600px;
}
.chatsPage .chatRow {
  background-color: #f5f5f5;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  color: #364135;
  display: grid;
  grid-template-columns: 60px 1fr 100px;
  grid-gap: 15px;
  margin: 0 0 20px;
  padding: 20px;
}
.chatsPage .chatRow:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.chatsPage .chatRow.chatRow--unread {
  -webkit-box-shadow: 0px 0px 6px 0px rgb(133, 196, 64);
  -moz-box-shadow: 0px 0px 6px 0px rgb(133, 196, 64);
  box-shadow: 0px 0px 6px 0px rgb(133, 196, 64);
  font-weight: bold;
}
.chatsPage .chatRow .chatRow-avatar img {
  border-radius: 50%;
  display: block;
  height: 60px;
  width: 60px;
}
.chatsPage .chatRow .chatRow-text h3 {
  font-weight: 600;
  margin: 0 0 5px;
}
.chatsPage .chatRow .chatRow-text p {
  font-size: 11px;
  margin: 0;
}
.chatsPage .chatRow .chatRow-date {
  color: #364135;
  font-size: 11px;
  text-align: right;
}

.chatPage .chatPage-user {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.chatPage .chatPage-user .chatPage-userAvatar {
  margin-right: 20px;
  width: 60px;
}
.chatPage .chatPage-user .chatPage-userAvatar img {
  border-radius: 50%;
  display: block;
  height: 60px;
  width: 60px;
}
.chatPage .chatPage-user .chatPage-userName {
  font-size: 20px;
  font-weight: bold;
}
.chatPage .chatPage-messages {
  display: flex;
  flex-direction: column;
}
.chatPage .chatPage-messages .chatPage-message {
  background: #fff;
  border: 2px solid #85C440;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 300px;
}
.chatPage .chatPage-messages .chatPage-message .chatPage-messageText {
  margin-bottom: 5px;
}
.chatPage .chatPage-messages .chatPage-message .chatPage-messageText p {
  margin: 0;
}
.chatPage .chatPage-messages .chatPage-message .chatPage-messageMeta {
  color: #999;
  font-size: 12px;
  text-align: right;
}
.chatPage .chatPage-messages .chatPage-message:not(.chatPage-messageMine) {
  background: #85C440;
  align-self: flex-end;
  color: #fff;
}
.chatPage .chatPage-messages .chatPage-message:not(.chatPage-messageMine) .chatPage-messageText {
  text-align: right;
}
.chatPage .chatPage-messages .chatPage-message:not(.chatPage-messageMine) .chatPage-messageMeta {
  color: #ddd;
}
.chatPage .chatPage-messages .chatPage-message.chatPage-messageMine {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.chatPage .chatForm {
  margin: 20px 0;
}
.chatPage .chatForm .chatForm-layout {
  display: grid;
  grid-template-columns: auto 120px;
  grid-gap: 20px;
  align-items: center;
}
.chatPage .chatForm .chatForm-layout .form-group {
  margin: 0;
}

.notificationSwitch {
  display: inline-block;
  line-height: 20px;
  margin: 10px 0;
  padding-left: 45px;
  position: relative;
}
.notificationSwitch::before {
  border-radius: 10px;
  border: 2px solid #ddd;
  content: "";
  height: 20px;
  width: 35px;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.4s;
}
.notificationSwitch::after {
  content: "";
  background: #85C440;
  border-radius: 8px;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 16px;
  width: 16px;
}
.notificationSwitch.notificationSwitch-disabled::after {
  background: #aaa;
  left: 17px;
}

.offersPage .offersHeading {
  color: #85C440;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 10px;
}
.offersPage .offersList {
  margin-bottom: 50px;
  max-width: 600px;
}
.offersPage .offerRow {
  background-color: #f5f5f5;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  color: #364135;
  display: grid;
  grid-template-columns: 60px 1fr 100px;
  grid-gap: 15px;
  align-items: center;
  margin: 0 0 20px;
  padding: 10px 20px;
}
.offersPage .offerRow:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.offersPage .offerRow .offerRow-image img {
  border-radius: 50%;
  display: block;
  height: 60px;
  width: 60px;
}
.offersPage .offerRow .offerRow-text h3 {
  font-weight: 600;
  margin: 0 0 5px;
}
.offersPage .offerRow .offerRow-text p {
  font-size: 11px;
  margin: 5px 0 0;
}

.offerForm {
  max-width: 600px;
}
.offerForm .form-group.offer_category {
  display: block;
  margin: 30px 0;
}
.offerForm .form-group.offer_category .form-control-label {
  font-weight: bold;
  margin: 0 0 10px;
}
.offerForm .form-group.offer_category .form-control-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  max-width: 600px;
}
.offerForm .form-group.offer_category .form-control-wrapper span.radio ~ span.radio {
  margin-top: 0;
}

.offerAskForm {
  text-align: center;
}
.offerAskForm p {
  margin: 0 0 20px;
}

.packagesPage .packagesList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  align-items: flex-end;
  margin-bottom: 30px;
}
.packagesPage .packageRow {
  background-color: #f5f5f5;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  color: #364135;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}
.packagesPage .packageRow:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
@media (min-width: 768px) {
  .packagesPage .packageRow:nth-child(1), .packagesPage .packageRow:nth-child(5) {
    min-height: 150px;
  }
  .packagesPage .packageRow:nth-child(2), .packagesPage .packageRow:nth-child(4) {
    min-height: 175px;
  }
  .packagesPage .packageRow:nth-child(3) {
    min-height: 200px;
  }
}
.packagesPage .packageRow .packageRow-size {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px;
}
.packagesPage .packageRow .packageRow-text {
  font-size: 14px;
}
.packagesPage .packageRow .packageRow-price {
  color: #85C440;
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0;
}
.packagesPage .packageActions {
  margin-top: 100px;
}

.notificationsPage .notificationsCalculator {
  max-width: 600px;
  margin: 50px auto;
}
.notificationsPage .notificationsCalculator .notificationsCalculator-row {
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 10px 0;
}
.notificationsPage .notificationsCalculator .notificationsCalculator-row .notificationsCalculator-label {
  text-align: right;
}
.notificationsPage .notificationsCalculator .notificationsCalculator-row .notificationsCalculator-value {
  font-size: 16px;
}
.notificationsPage .notificationsCalculator .notificationsCalculator-row #total_price {
  color: #00AD53;
  font-weight: bold;
}

.ratesPage .headingText {
  text-align: center;
}
.ratesPage .rateStar {
  background: url(../images/ratae-star.svg) center no-repeat;
  background-size: cover;
  height: 120px;
  width: 120px;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.ratesPage .rateRow {
  background-color: #f5f5f5;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  color: #364135;
  margin: 0 0 20px;
  padding: 10px 20px;
}
.ratesPage .rateRow .rateRow-author {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.ratesPage .rateRow .rateRow-author span.rateRow-star {
  background: url(../images/ratae-star.svg) right center no-repeat;
  display: inline-block;
  line-height: 20px;
  padding-right: 20px;
  vertical-align: middle;
}
.ratesPage .rateRow .rateRow-text {
  max-width: 60%;
}
.ratesPage .rateRow .rateRow-text .rateRow-date {
  font-size: 12px;
  display: inline-block;
  margin-right: 20px;
}
.ratesPage .rateRow .rateRow-text .rateRow-content {
  display: inline-block;
}
.ratesPage .rateRow .rateAnswer {
  margin-left: auto;
}
@media (min-width: 768px) {
  .ratesPage .rateRow .rateAnswer {
    max-width: 60%;
  }
}
.ratesPage .rateRow .rateAnswer .rateAnswer-label {
  font-weight: bold;
  margin-bottom: 5px;
}
.ratesPage .rateRow .rateAnswer .rateAnswer-text {
  font-style: italic;
}
@media (min-width: 768px) {
  .ratesPage .rateForm .rateForm-layout {
    display: grid;
    grid-template-columns: auto 150px;
    grid-gap: 20px;
  }
}

.subscriptionsPage .subscriptionCategory {
  display: grid;
  grid-template-columns: auto 200px 200px;
  align-items: center;
  margin: 0 0 20px;
}
.subscriptionsPage .subscriptionCategory > * {
  padding: 5px;
}
.subscriptionsPage .subscriptionCategory .subscriptionCategory-expired_date {
  text-align: center;
}
.subscriptionsPage .subscriptionCategory .subscriptionCategory-action {
  text-align: center;
}
.subscriptionsPage .subscriptionCategory.subscriptionCategory--heading {
  border-bottom: 1px solid #000;
  font-weight: bold;
}

.loginPage .bgContent {
  padding-top: 50px;
  padding-bottom: 50px;
}

form.pageForm .headingText {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px;
  text-align: center;
}
form.pageForm .form-group {
  display: flex;
  align-items: flex-start;
  margin: 0 0 10px;
}
form.pageForm .form-group.hidden {
  display: none;
}
form.pageForm .form-control-label {
  padding-top: 8px;
  padding-right: 10px;
  text-align: right;
  width: 150px;
}
form.pageForm .form-control-wrapper, form.pageForm .form-wrapper {
  flex-grow: 1;
}
form.pageForm abbr {
  color: #fd397a;
  font-weight: bold;
  text-decoration: none;
}
form.pageForm .form-control {
  background: #fff;
  border: 1px solid #C5C5C5;
  border-radius: 17.5px;
  display: block;
  line-height: 25px;
  padding: 5px 15px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
}
form.pageForm .form-control:focus {
  border-color: #8CC14A;
}
form.pageForm .invalid-feedback {
  color: red;
  display: block;
  font-size: 80%;
  margin: 0 0 5px;
}
form.pageForm .form-actions {
  padding-left: 150px;
}
form.pageForm .form-actions input {
  max-width: 100%;
}
form.pageForm .form-group.file .fileInput {
  display: inline-flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
form.pageForm .form-group.file .fileInput::after {
  border: 2px solid #fff;
  border-radius: 10px;
  content: "+";
  display: inline-block;
  font-weight: bold;
  height: 20px;
  line-height: 16px;
  margin-left: 5px;
  width: 20px;
}
form.pageForm .form-group.file .fileInput ~ input[type=file] {
  display: none;
}
form.pageForm .form-group.check_boxes {
  position: relative;
}
form.pageForm .form-group.check_boxes span.checkbox {
  display: block;
}
form.pageForm .form-group.check_boxes span.checkbox ~ span.checkbox {
  margin-top: 10px;
}
form.pageForm .form-group.check_boxes .form-control-label {
  display: block;
  text-align: left;
  width: 100%;
}
form.pageForm .form-group.check_boxes .collection_check_boxes {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 20px;
  text-align: left;
  padding: 2px 0 2px 30px;
  position: relative;
  width: unset;
}
form.pageForm .form-group.check_boxes .collection_check_boxes::before, form.pageForm .form-group.check_boxes .collection_check_boxes::after {
  content: "";
  position: absolute;
  top: 0;
  height: 24px;
  width: 24px;
  left: 0;
}
form.pageForm .form-group.check_boxes .collection_check_boxes::before {
  border: 2px solid #C5C5C5;
  border-radius: 5px;
}
form.pageForm .form-group.check_boxes .collection_check_boxes::after {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjOENDMTRBIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlsaW5lIHBvaW50cz0iMjAgNiA5IDE3IDQgMTIiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  opacity: 0;
}
form.pageForm .form-group.check_boxes input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
form.pageForm .form-group.check_boxes input[type=checkbox]:checked ~ .collection_check_boxes::before {
  border-color: #8CC14A;
}
form.pageForm .form-group.check_boxes input[type=checkbox]:checked ~ .collection_check_boxes::after {
  opacity: 1;
}
form.pageForm .form-group.radio_buttons {
  position: relative;
}
form.pageForm .form-group.radio_buttons span.radio {
  display: block;
}
form.pageForm .form-group.radio_buttons span.radio ~ span.radio {
  margin-top: 10px;
}
form.pageForm .form-group.radio_buttons .form-control-label {
  display: block;
  text-align: left;
  width: 100%;
}
form.pageForm .form-group.radio_buttons .collection_radio_buttons {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 20px;
  text-align: left;
  padding: 2px 0 2px 30px;
  position: relative;
  width: unset;
}
form.pageForm .form-group.radio_buttons .collection_radio_buttons::before, form.pageForm .form-group.radio_buttons .collection_radio_buttons::after {
  content: "";
  position: absolute;
  top: 0;
  height: 24px;
  width: 24px;
  left: 0;
}
form.pageForm .form-group.radio_buttons .collection_radio_buttons::before {
  border: 2px solid #C5C5C5;
  border-radius: 5px;
}
form.pageForm .form-group.radio_buttons .collection_radio_buttons::after {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjOENDMTRBIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlsaW5lIHBvaW50cz0iMjAgNiA5IDE3IDQgMTIiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  opacity: 0;
}
form.pageForm .form-group.radio_buttons input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
form.pageForm .form-group.radio_buttons input[type=radio]:checked ~ .collection_radio_buttons::before {
  border-color: #8CC14A;
}
form.pageForm .form-group.radio_buttons input[type=radio]:checked ~ .collection_radio_buttons::after {
  opacity: 1;
}
form.pageForm .form-group.boolean {
  position: relative;
}
form.pageForm .form-group.boolean .form-check-label {
  display: inline-block;
  font-weight: normal;
  line-height: 20px;
  text-align: left;
  padding: 2px 0 2px 30px;
  width: unset;
}
form.pageForm .form-group.boolean .form-check-label::before, form.pageForm .form-group.boolean .form-check-label::after {
  content: "";
  position: absolute;
  top: 0;
  height: 24px;
  width: 24px;
  left: 0;
}
form.pageForm .form-group.boolean .form-check-label::before {
  border: 2px solid #C5C5C5;
  border-radius: 5px;
}
form.pageForm .form-group.boolean .form-check-label::after {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjOENDMTRBIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlsaW5lIHBvaW50cz0iMjAgNiA5IDE3IDQgMTIiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  opacity: 0;
}
form.pageForm .form-group.boolean input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
form.pageForm .form-group.boolean input[type=checkbox]:checked ~ .form-check-label::before {
  border-color: #8CC14A;
}
form.pageForm .form-group.boolean input[type=checkbox]:checked ~ .form-check-label::after {
  opacity: 1;
}

form.loginForm {
  margin: 0 auto 50px;
  max-width: 360px;
}
form.loginForm .form-control-label {
  width: 100px;
}
form.loginForm .form-actions {
  padding-left: 100px;
}

.registerInfo {
  border-top: 1px dashed #8CC14A;
  margin: 30px auto;
  max-width: 500px;
  padding-top: 30px;
  text-align: center;
}
.registerInfo .headingText {
  margin-bottom: 10px;
}
.registerInfo .registerInfo-subheading {
  color: #2F2F2F;
  font-size: 15px;
  margin: 10px 0;
}
.registerInfo .registerInfo-text {
  color: #2F2F2F;
  font-size: 13px;
  margin: 10px auto;
}
.registerInfo .registerInfo-buttons {
  margin-top: 30px;
}

.registerPage .bgContent {
  padding-top: 30px;
  padding-bottom: 30px;
}
.registerPage .pageForm .headingText {
  margin: 0;
}
.registerPage .registerPage-step {
  color: #8CC14A;
  margin: 0 0 30px;
  text-align: center;
}

form.accountForm .form-control-label {
  font-weight: bold;
}
form.accountForm .registerStep1 {
  margin: 0 auto 50px;
  max-width: 600px;
}
form.accountForm .registerStep1 .form-group {
  max-width: 480px;
}
form.accountForm .registerStep1 .form-control-label {
  width: 230px;
}
form.accountForm .registerStep1 .form-wrapper .form-control {
  width: 100%;
}
form.accountForm .registerStep1 .form-actions {
  margin-top: 30px;
  text-align: right;
}
form.accountForm .registerStep2 {
  margin: 0 auto 50px;
  max-width: 600px;
}
form.accountForm .registerStep2 .form-group .form-control-label {
  width: 230px;
}
form.accountForm .registerStep2 .form-group.company_images {
  align-items: flex-start;
}
form.accountForm .registerStep2 .form-group.file .fileInput {
  display: inline-flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
form.accountForm .registerStep2 .form-group.file .fileInput::after {
  border: 2px solid #fff;
  border-radius: 10px;
  content: "+";
  display: inline-block;
  font-weight: bold;
  height: 20px;
  line-height: 16px;
  margin-left: 5px;
  width: 20px;
}
form.accountForm .registerStep2 .form-group.file .fileInput ~ input[type=file] {
  display: none;
}
form.accountForm .registerStep2 .form-group {
  max-width: 480px;
}
form.accountForm .registerStep2 .hoursInputs-label {
  font-weight: bold;
  text-align: right;
  width: 200px;
}
form.accountForm .registerStep2 .hoursInputs {
  display: grid;
}
form.accountForm .registerStep2 .hoursInputs .hoursInputs-layout {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px 1fr 1fr;
  align-items: flex-start;
  margin: 0 0 10px;
}
@media (min-width: 768px) {
  form.accountForm .registerStep2 .hoursInputs .hoursInputs-layout {
    grid-template-columns: 200px 1fr 1fr;
  }
}
form.accountForm .registerStep2 .hoursInputs .hoursInputs-layout .hoursInputs-rowLabel {
  display: inline-block;
  margin: 5px 0;
  text-align: right;
}
form.accountForm .registerStep2 .hoursInputs .hoursInputs-layout .form-group {
  margin: 0;
}
form.accountForm .registerStep2 .form-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
form.accountForm .registerStep2 .form-actions .btn {
  margin: 5px;
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  max-width: 600px;
  margin: 0 auto;
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.radio {
  padding-bottom: 30px;
  position: relative;
  text-align: center;
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.radio ~ span.radio {
  margin: 0;
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.radio .collection_radio_buttons {
  font-weight: bold;
  text-align: center;
  padding: 0;
  position: unset;
  width: 100%;
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.radio .collection_radio_buttons::before, form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.radio .collection_radio_buttons::after {
  top: unset;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.checkbox {
  padding-bottom: 30px;
  position: relative;
  text-align: center;
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.checkbox ~ span.checkbox {
  margin: 0;
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.checkbox .collection_check_boxes {
  font-weight: bold;
  text-align: center;
  padding: 0;
  position: unset;
  width: 100%;
}
form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.checkbox .collection_check_boxes::before, form.accountForm .form-group.category_list_wrapper .form-control-wrapper span.checkbox .collection_check_boxes::after {
  top: unset;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
form.accountForm .registerPage-category {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
form.accountForm .registerPage-category .registerPage-categoryName {
  color: #8CC14A;
  font-weight: bold;
  margin: 0 0 10px;
  text-align: center;
  grid-column: 1/3;
}
form.accountForm .registerPage-category .registerPage-filters {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 1fr;
}
form.accountForm .registerPage-category .registerPage-filters .registerPage-filtersLabel {
  font-weight: bold;
  line-height: 20px;
  text-align: right;
}

form.registerStep3Form .form-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
form.registerStep3Form .form-actions .btn {
  margin: 5px;
}

#images_preview {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px;
}
#images_preview .image_box {
  border: 1px solid #eee;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin: 5px;
  position: relative;
  width: 80px;
}
#images_preview .image_box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
#images_preview .image_box .image_remove_btn {
  background: url(../images/icon-close.svg);
  background-size: contain;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}

#logo_preview {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px;
}
#logo_preview .logo_box {
  border: 1px solid #eee;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin: 5px;
  position: relative;
  width: 80px;
}
#logo_preview .logo_box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
#logo_preview .logo_box .logo_remove_btn {
  background: url(../images/icon-close.svg);
  background-size: contain;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}

#documents_preview {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px;
}
#documents_preview .document_box {
  background: url(../images/icon-document.svg) center no-repeat;
  border: 1px solid #eee;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin: 5px;
  position: relative;
  width: 80px;
}
#documents_preview .document_box .document_remove_btn {
  background: url(../images/icon-close.svg);
  background-size: contain;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}
#documents_preview .document_box .document_title {
  line-break: anywhere;
  position: absolute;
  top: 80px;
}

:root {
  --swiper-theme-color: #BFDBA0;
}

body {
  color: #2F2F2F;
  font-family: "Open Sans", sans-serif;
}

.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}

.confirmMessage {
  background: #fafafa;
  border: 1px solid #ccc;
  color: #666;
  font-size: 16px;
  padding: 15px;
  text-align: center;
}

.btn.btn-primary, .btn.btn-secondary {
  border-radius: 26px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
  display: inline-block;
  line-height: 23px;
  padding: 7px 30px;
  text-transform: uppercase;
  text-align: center;
}
.btn.btn-primary.btn-large, .btn.btn-secondary.btn-large {
  line-height: 22px;
  padding: 15px;
}

.btn.btn-primary {
  background: transparent linear-gradient(120deg, #85C440 0%, #6BAB26 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

.btn.btn-secondary {
  background: #C5C5C5;
  color: #707070;
}

@media (min-width: 992px) {
  .bgContent {
    margin: 0 -15px;
    min-height: 600px;
    padding: 0 30px 0 350px;
    position: relative;
  }
  .bgContent::before {
    background: url(../images/content-bg.jpg) center no-repeat;
    background-size: auto 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 312px;
  }
}
@media (min-width: 1200px) {
  .bgContent {
    padding-right: 100px;
    padding-left: 380px;
  }
  .bgContent::before {
    width: 350px;
  }
}

.headingText {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px;
}

.actionsList {
  text-align: right;
  margin: 20px 0;
}

.userLayout {
  padding: 20px 0;
}
@media (min-width: 768px) {
  .userLayout {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 20px;
    min-height: calc(100vh - 337px);
  }
}
.userLayout .userLayout-sidebarLabel {
  background-color: #85C440;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  color: #fff;
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .userLayout .userLayout-sidebarLabel {
    display: none;
  }
}
.userLayout .userLayout-sidebar .userMenu {
  display: none;
}
@media (min-width: 768px) {
  .userLayout .userLayout-sidebar .userMenu {
    display: block;
  }
}

.userMenu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.userMenu li {
  margin: 0 0 15px;
}
.userMenu li a {
  background-color: #f5f5f5;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  color: #364135;
  display: block;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
}
.userMenu li a.active {
  background-color: #85C440;
  color: #fff;
}
.userMenu li a:hover {
  background-color: #85C440;
  color: #fff;
}
.userMenu li a > span {
  display: inline-block;
  position: relative;
}
.userMenu li a > span > span {
  background-color: #fff;
  border-radius: 50%;
  color: #85C440;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -8px;
  right: -20px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
}
.pagination > em, .pagination > span, .pagination > a {
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  line-height: 20px;
  margin: 0 2px;
  padding: 5px;
}
.pagination > span {
  color: #999;
}
.pagination > em {
  font-weight: bold;
  font-style: normal;
}
.pagination > a:hover {
  background-color: #8CC14A;
  color: #fff;
}